@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


// Style guide

// Background: #202638
// Secondary: #191e2c
// Primary: #2e3751
// Blue: #00B4D8;

body {
  font-family: 'Open Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

a:link {
  color:#00B4D8;
}

a:visited {
  color:#00B4D8;
}

.github-link{
  display:flex;
  align-items:center;
  justify-content: center;
}

.github-img{
  width: 25px;
  margin-right:10px;
}

.github-img-big{
  width:50px;
  padding-right:20pt;
}

.fill {
  border: 2px solid #f7f7f7;
  text-align: center;
  overflow: hidden;
  position: relative;
  font-family: 'Montserrat', sans-serif;

  transition: .3s;
  &:after {
    position: absolute;
    transition: .3s;
    content: '';
    width: 0;
    bottom: 0;
    background: #f7f7f7;
    height: 100%;
    left: -10%;
    z-index: -1;
  }
  &:hover {
    color: #222326;
    cursor: pointer;
    &:after {
      left: -10%;
      width: 120%;

    }
  }
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.h1-w-montserrat {
  color: white;
  font-size: 64px;
  font-family: 'Montserrat', sans-serif;
  @media (max-width: 800px) {
    font-size:40px;
  }
}

.h1-b-montserrat {
  color: black;
  font-size: 64px;
  font-family: 'Montserrat', sans-serif;
  @media (max-width: 800px) {
    font-size:40px;
  }
}

.h2-w-montserrat {
  color: white;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  @media (max-width: 800px) {
    font-size:24px;
  }
}

.h2-b-montserrat {
  color: black;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  @media (max-width: 800px) {
    font-size:24px;
  }
}

.h3-w-montserrat {
  color: white;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  @media (max-width: 800px) {
    font-size:22px;
  }
}

.h3-b-montserrat {
  color: black;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  @media (max-width: 800px) {
    font-size:22px;
  }
}

.p-w-opensans {
  color: white;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  line-height: 32px;
  font-weight: 300;
}

.p-b-opensans {
  color: black;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  line-height: 32px;
  font-weight: 400;
}

.small-w-opensans {
  color: white;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  line-height: 32px;
  font-weight: 400;
}

.small-b-opensans {
  color: black;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  line-height: 32px;
  font-weight: 400;
}

// PROJECT ELEMENTS

.skill {
  border-radius:16px;
  padding-left:8px;
  padding-right:8px;
  background-color: #52618f;
}

.skill_cont {
  display: flex;
  gap:8px;
  flex-wrap: wrap;
}

.proj_img{
  width:100%;
  height:600px;
  object-fit: cover;
  border-radius:8px;
  @media (max-width: 800px) {
      height:500px;
    }
}

//HOME NAVBAR

.header1 {
  background-color: none;
  }

  .header2 {
    background-color: #191e2c;
  }

//NOOT NAVBAR
.header1Noot {
  background-color: none;
}

.header2Noot {
  background-color: #191e2c;
}

//SHOW TRACKER NAVBAR
.header1ShowTracker {
  background-color: none;
}

.header2ShowTracker {
  background-color: #191e2c;
}

//LIGHTBULB NAVBAR
.header1Lightbulb {
  background-color: none;
}

.header2Lightbulb {
  background-color: #191e2c;
}

//MODERN KIBBLE NAVBAR
.header1Kibble {
  background-color: none;
  transition: 0.4s;
}

.header2Kibble {
  background-color: #191e2c;
}

//SECURE HIVE NAVBAR
.header1SecureHive {
  background-color: none;
}

.header2SecureHive {
  background-color: #191e2c;
}

.Art {
  flex:2;
  width:100%;
  @media (max-width: 1200px) {
    display: none;
    }
}

// .NavBarContainer {
//   width:100%;
//   height:50pt;
//   position:fixed;
//   display:flex;
//   justify-content:center;
//   // background-color:rgba(42, 43, 46, 0.9);
//   color:white;
// }


// ANIMATIONS //

@media (prefers-reduced-motion: no-preference) {
  .fadeIn1 {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  .fadeIn2 {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: both;
  }
  .fadeIn3 {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 2.5s;
    animation-fill-mode: both;
  }
    .circle1 {
    left: 10%;
    border-radius:100%;
    position: relative;
    width: 90px;
    height: 90px;
    background-color: #00B4D8;
    color: white;
    bottom: -300px;
    -webkit-animation: circle-move1 4s infinite;
    animation: circle-move1 4s infinite;
    @media (max-width: 1200px) {
      display: none;
      }
    }
    .circle2 {
    left: 40%;
    border-radius:100%;
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #00B4D8;
    color: white;
    bottom: -140px;
    -webkit-animation: circle-move2 2.8s infinite;
    animation: circle-move2 2.8s infinite;
    }
        .circle3 {
            left: 20%;
            border-radius:100%;
            position: relative;
            width: 160px;
            height: 160px;
            background-color: #00B4D8;
            color: white;
            bottom: 80px;
            -webkit-animation: circle-move3 2.5s infinite;
            animation: circle-move3 2.5s infinite;
            }
            .circle4 {
                left: 50%;
                border-radius:100%;
                position: relative;
                width: 70px;
                height: 70px;
                background-color: #00B4D8;
                color: white;
                bottom: -90px;
                -webkit-animation: circle-move4 4.5s infinite;
                animation: circle-move4 4.5s infinite;
                }
                .circle5 {
                    left: 80%;
                    border-radius:100%;
                    position: relative;
                    width: 120px;
                    height: 120px;
                    background-color: #00B4D8;
                    color: white;
                    bottom: 110px;
                    -webkit-animation: circle-move5 3s infinite;
                    animation: circle-move5 3s infinite;
                    }
  }

  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @keyframes circle-move1 {
    50% {bottom: -280px;}
  }
  @keyframes circle-move2 {
    50% {bottom: -130px;}
  }
  @keyframes circle-move3 {
    50% {bottom: 70px;}
  }
  @keyframes circle-move4 {
    50% {bottom: -70px;}
  }
  @keyframes circle-move5 {
    50% {bottom: 125px;}
  }
